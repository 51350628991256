import Splide from '@splidejs/splide';
import $ from "jquery";

export function preparePressReleaseToggles() {
    if (document.getElementsByClassName('press-header__button').length > 0) {

        fitPressNavToVisualHeight();
    
        const pressBox = $('.press-header-box__title');
        const pressImage = $('.press-header__image img');
        const pressSummary = $('.press-header__summary p');
        const pressLink = $('.press-header__link a');
        const pressDate = $('.press-header__link span');

        $('.press-header__button').on('click', function() {
            pressBox.text(b64DecodeUnicode($(this).data('title')));

            pressImage.attr('src', $(this).data('image'));    
            pressImage.attr('width', $(this).data('imgWidth'));    
            pressImage.attr('height', $(this).data('imgHeight'));    
            pressSummary.text(b64DecodeUnicode($(this).data('summary')));
            pressLink.attr('href', $(this).data('url'));
            pressDate.text($(this).data('dateString'));

            const pressNewImage = new Image();
            pressNewImage.src = pressImage.attr('src');
            pressNewImage.onload = function() {
                fitPressNavToVisualHeight();
            } ;
            
            $('.press-header__button').removeClass('press-header__button--active');
            $(this).addClass('press-header__button--active');
            
        });
    }
}

function fitPressNavToVisualHeight() {
    const height = $('.press-header__image img').outerHeight();
    if(height > 0) {
        $('.press-header__list ul').css('height', height + 'px')
    }
    
}

export function renderFileCarousel() {

    if (document.getElementsByClassName('article-files__splide').length > 0) {
        const slider = new Splide('.article-files__splide', {
            type: 'slide',
            perPage: 7,
            perMove: 7,
            gap: '1rem',
            pagination: false,
            lazyLoad: 'nearby',
            preloadPages: 10,
            width: '100%',
            breakpoints: {
                
                850: {
                    perPage: 3,
                    perMove: 3,
                }
            },              
            
        });

        slider.mount();
    }
}

function b64DecodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}

fitPressNavToVisualHeight();
renderFileCarousel();
preparePressReleaseToggles();