import Navigator from "@oom/page-loader/src/navigator";
import { showroomNavUpdate } from "./showroom"
import loadCarousel from "./carousel"
import $ from "jquery";
import { loadTopicSplide } from "./topic-list";
import { lightboxUpdate } from "./lightbox";
import { createDateRangePicker, initSearchButtons } from "./newsroom-archive";
import { loadVideos } from "./video";
import { renderFileCarousel } from "./press";
import { preparePressReleaseToggles } from "./press";
import { checkForActiveMediaOverlay } from "./media-overlay";
import { createMapLabels } from "./header";
import { initFixedNav } from "./fixed-nav";
import { initMediaCenterSearch } from "./media-search";
import { initAutocomplete } from "./newsroom-search";
import { initToggleButtons } from "./toggles";
import { initTalkIframeModals } from "./talks";
//import { checkAndInitLiveWebinar } from "./webinar";
import { initPlaner, leavePlanerConfirm } from "./planer";
import { initPublications } from "./publications";

export const nav = new Navigator(async (load, event) => {

    if(typeof event !== 'undefined' && event.target.closest('.carousel-dragging')) {
        return;
    }

    if(document.querySelector('.page--warn-on-nav')) {

        const link = event.target.closest('a');

        if (typeof(link.dataset.allowNav) === 'undefined') {
            leavePlanerConfirm(link.href);
            return;
        }
    }

    const body = $('body');
 
    body.addClass('loading');

    //Load the page
    const page = await load();

    //await page.replaceStyles(); //Load the new css styles defined in <head> not present currently
    //await page.replaceScripts(); //Load the new js files defined in <head> not present currently

    await page.replaceContent(".nav-button");
    await page.replaceContent(".header-map__subnavs");
    await page.replaceContent(".mobile-nav .header-nav__meta");
    await page.replaceContent(".header-nav .header-nav__meta");
    await page.replaceContent(".header-breadcrumbs");
    await page.replaceContent("meta[name=description]");
    await page.replaceContent("meta[http-equiv=content-language]");
    await page.replaceContent("link[rel=canonical]");
    await page.replaceContent(".header-map__labels");
    await page.replaceContent("[data-name=Plan]");
    await page.replaceContent(".footer-info__nav");
    await page.replaceContent('.footer-info__address');
    await page.replaceContent('#page-styles');
    await page.replaceContent('.footer-top');
    
    
    await page.replaceContent("main", function() {

        const url = new URL(page.url);
        page.updateState();

        if(url.pathname.startsWith('/en/')) {
            $('html').attr('lang', 'en');
        }
        else {
            $('html').attr('lang', 'de');
        }

        loadCarousel();
        loadTopicSplide();
        lightboxUpdate();
        showroomNavUpdate();
        createDateRangePicker();
        initSearchButtons();
        loadVideos();
        renderFileCarousel();
        preparePressReleaseToggles();
        checkForActiveMediaOverlay();
        createMapLabels();
        initFixedNav();
        initMediaCenterSearch();
        initAutocomplete();
        initToggleButtons();
        initTalkIframeModals();
        initPlaner();
        initPublications();
        
        
        $('#nav-toggle').removeClass('open');
        $('.header-toggle').removeClass('header-toggle--open');

        $('.header-map__nav-item--current').removeClass('header-map__nav-item--current');
        $('.header-map__nav-item a[href="' + url.pathname + '"]').parent().addClass('header-map__nav-item--current')

        const pageElementList = page.dom.getElementsByClassName('page');
        if(pageElementList.length == 1 && document.getElementsByClassName('page').length == 1) {
            document.getElementsByClassName('page')[0].classList = pageElementList[0].classList;
        }
        
        //checkAndInitLiveWebinar();

    }); //Replace the <main> element

    await page.updateState(); //Update the page status (change url, title etc)
    await page.resetScroll(); //Reset the scroll position

    await body.removeClass('loading');

    if($('body').attr('data-cookies') == 'true' && typeof gtag !== 'undefined') {
        gtag('config', 'G-5NB7ZC8WS6', { 'anonymize_ip': true, 'page_path': window.location.pathname });
        //gtag('config', 'UA-2169412-1', { 'anonymize_ip': true, 'page_path': window.location.pathname });
    }

});

nav.addFilter(function(el, url){
    return !$(el).hasClass('lightbox');
});

nav.addFilter(function(el, url){
    return !$(el).hasClass('lang-switcher');
});

nav.addFilter(function(el, url){
    return el.dataset.action !== 'download';
});

nav.addFilter(function(el, url){
    return url.indexOf('processwire') === -1;
});


/* uncomment for Talks

nav.addFilter(function(el, url){

    //Bühne should always load fully
    return (location.href.indexOf('/buehne/') === -1 && url.indexOf('/buehne/') === -1);
});
*/

//Init the navigation, capturing all clicks in links and form submits
nav.init();