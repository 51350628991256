import $, { cssNumber } from "jquery";

export function initToggleButtons() {
    $('[data-base-toggle]').each(function() {

        $(this).on('click', function() {
            const button = $(this);
            const target = $(button.data('baseToggle'));
            const toggleClass = button.data('baseToggleClass');
            const toggleText = button.data('baseToggleText');
            const toggleAltText = button.data('baseToggleAlt');

            if(target.length == 1) {
                target.toggleClass(toggleClass);
                
                if(target.hasClass(toggleClass)) {
                    button.text(toggleText);
                }
                else {
                    button.text(toggleAltText);
                }
            }
        });
    });
}

initToggleButtons();