const spinnerHtml = '<div class="spinner">\n' +
    '    <div class="rect1"></div>\n' +
    '    <div class="rect2"></div>\n' +
    '    <div class="rect3"></div>\n' +
    '    <div class="rect4"></div>\n' +
    '    <div class="rect5"></div>\n' +
    '</div>';



export function addSpinner(element) {
    element.classList.add('loading');
    element.insertAdjacentHTML('beforeend', spinnerHtml);
}

export function removeSpinner (element) {
    element.classList.remove('loading');
    element.querySelector('.spinner').remove();
}

