import 'vanilla-cookieconsent';

let cc = null;

function setupCookieConsent() {

    
    cc = initCookieConsent();

    
    cc.run({
        current_lang: document.documentElement.lang,
        autoclear_cookies: true,
        theme_css: '',
        page_scripts: true,
        mode: 'opt-out',
        revision: 1,

        onAccept: function (cookie) {
            if (!cc.allowedCategory('analytics')) {
                document.querySelector('body').setAttribute('data-cookies', 'false')
            }
            else {
                document.querySelector('body').setAttribute('data-cookies', 'true')
            }
        },

        onChange: function () {
            if (!cc.allowedCategory('analytics')) {
                document.querySelector('body').setAttribute('data-cookies', 'false')
            }
            else {
                document.querySelector('body').setAttribute('data-cookies', 'true')
            }
        },


        languages: {
            'en': {
                consent_modal: {
                    title: 'We use cookies!',
                    description: 'Hi, this website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
                    primary_btn: {
                        text: 'Accept all',
                        role: 'accept_all'              
                    },
                    secondary_btn: {
                        text: 'Reject all',
                        role: 'accept_necessary'        
                    }
                },
                settings_modal: {
                    title: 'Cookie preferences',
                    save_settings_btn: 'Save settings',
                    accept_all_btn: 'Accept all',
                    reject_all_btn: 'Reject all',
                    close_btn_label: 'Close',
                    cookie_table_headers: [
                        {col1: 'Name'},
                        {col2: 'Domain'},
                        {col3: 'Expiration'},
                    ],
                    blocks: [
                        {
                            title: 'Cookie usage 📢',
                            description: 'I use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="/datenschutz" class="cc-link">privacy policy</a>.'
                        }, {
                            title: 'Strictly necessary cookies',
                            description: 'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly',
                            toggle: {
                                value: 'necessary',
                                enabled: true,
                                readonly: true          
                            }, 
                            cookie_table: [  
                                {
                                    col1: 'wire',
                                    col2: 'iba.online',
                                    col3: 'End of browser session',
                                },
                                {
                                    col1: 'cc_cookie',
                                    col2: 'iba.online',
                                    col3: '182 days',
                                }                            
                            ]                        
                        }, 
                        {
                            title: 'Google Analytics',
                            description: 'These cookies allow the website to remember the choices you have made in the past',
                            toggle: {
                                value: 'analytics',
                                enabled: true,
                                readonly: false
                            },
                            cookie_table: [  
                                {
                                    col1: '_gat_*',
                                    col2: 'google.com',
                                    col3: 'Direct',
                                }, 
                                {
                                    col1: '_ga',
                                    col2: 'google.com',
                                    col3: '2 Jahre',
                                },
                                {
                                    col1: '_gid',
                                    col2: 'google.com',
                                }
                            ]
                        }, 
                        {
                            title: 'LinkedIn / Oribi',
                            description: 'Used to determine whether Oribi analyses can be performed for a specific domain',
                            toggle: {
                                value: 'analytics-linked-in',
                                enabled: true,
                                readonly: false
                            },
                            cookie_table: [  
                                {
                                    col1: 'AnalyticsSyncHistory',
                                    col2: 'linkedin.com',
                                    col3: '30 days',
                                }, 
                                {
                                    col1: 'bcookie',
                                    col2: 'linkedin.com',
                                    col3: '1 year',
                                },        
                                {
                                    col1: 'li_gc',
                                    col2: 'linkedin.com',
                                    col3: '6 month',
                                },      
                                {
                                    col1: 'li_sugr',
                                    col2: 'linkedin.com',
                                    col3: '90 days',
                                },                                                                      
                                {
                                    col1: 'lidc',
                                    col2: 'linkedin.com',
                                    col3: '1 day',
                                },                 
                                {
                                    col1: 'UserMatchHistory',
                                    col2: 'linkedin.com',
                                    col3: '30 days',
                                }              
                            ]
                        },                     
                        {
                            title: 'More information',
                            description: 'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="/kontakt">contact us</a>.',
                        }
                    ]
                }
            },
            'de': {
                consent_modal: {
                    title: 'Wir verwenden Cookies!',
                    description: 'Diese Website verwendet notwendige Cookies, um ihren ordnungsgemäßen Betrieb zu gewährleisten, und Tracking-Cookies, um zu verstehen, wie Sie mit ihr interagieren. <button type="button" data-cc="c-settings" class="cc-link">Hier auswählen</button>',
                    primary_btn: {
                        text: 'Alle akzeptieren',
                        role: 'accept_all'              
                    },
                    secondary_btn: {
                        text: 'Ablehnen',
                        role: 'accept_necessary'        
                    }
                },
                settings_modal: {
                    title: 'Cookie Einstellungen',
                    save_settings_btn: 'Einstellungen speichern',
                    accept_all_btn: 'Alle akzeptieren',
                    reject_all_btn: 'Ablehnen',
                    close_btn_label: 'Schließen',
                    cookie_table_headers: [
                        {col1: 'Name'},
                        {col2: 'Domain'},
                        {col3: 'Ablaufdatum'},
                    ],
                    blocks: [
                        {
                            title: 'Cookie Nutzung',
                            description: ' Wir verwenden Cookies, um die grundlegenden Funktionen der Website zu gewährleisten und um Ihr Online-Erlebnis zu verbessern. Sie können für jede Kategorie wählen, ob Sie sich ein- oder austragen möchten. Für weitere Einzelheiten zu Cookies und anderen sensiblen Daten lesen Sie bitte die vollständige <a href="/datenschutz" class="cc-link">Datenschutzerklärung</a>.'
                        }, {
                            title: 'Notwendige Cookies',
                            description: 'Diese Cookies sind für das reibungslose Funktionieren unserer Website unerlässlich. Ohne diese Cookies würde die Website nicht richtig funktionieren',
                            toggle: {
                                value: 'necessary',
                                enabled: true,
                                readonly: true
                            },
                            cookie_table: [  
                                {
                                    col1: 'wire',
                                    col2: 'iba.online',
                                    col3: 'Ende der Browsersizung',
                                },
                                {
                                    col1: 'cc_cookie',
                                    col2: 'iba.online',
                                    col3: '182 Tage',
                                }                                 
                            ]                          
                        }, {
                        title: 'Google Analytics',
                        description: 'Diese Cookies ermöglichen es der Website, sich an die von Ihnen in der Vergangenheit getroffenen Seitenaufrufe zu erinnern',
                        toggle: {
                            value: 'analytics',
                            enabled: true,
                            readonly: false
                        },
                        cookie_table: [  
                            {
                                col1: '_gat_*',
                                col2: 'google.com',
                                col3: 'Direkt',
                            }, 
                            {
                                col1: '_ga',
                                col2: 'google.com',
                                col3: '2 Jahre',
                            },
                            {
                                col1: '_gid',
                                col2: 'google.com',
                            }
                        ]
                        }, 
                        {
                            title: 'LinkedIn / Oribi',
                            description: 'Wird verwendet, um festzustellen, ob Oribi-Analysen für eine bestimmte Domäne durchgeführt werden können',
                            toggle: {
                                value: 'analytics-linked-in',
                                enabled: true,
                                readonly: false
                            },
                            cookie_table: [  
                                {
                                    col1: 'AnalyticsSyncHistory',
                                    col2: 'linkedin.com',
                                    col3: '30 Tage',
                                }, 
                                {
                                    col1: 'bcookie',
                                    col2: 'linkedin.com',
                                    col3: '1 Jahr',
                                },        
                                {
                                    col1: 'li_gc',
                                    col2: 'linkedin.com',
                                    col3: '6 Monate',
                                },      
                                {
                                    col1: 'li_sugr',
                                    col2: 'linkedin.com',
                                    col3: '90 Tage',
                                },                                                                      
                                {
                                    col1: 'lidc',
                                    col2: 'linkedin.com',
                                    col3: '1 Tag',
                                },                 
                                {
                                    col1: 'UserMatchHistory',
                                    col2: 'linkedin.com',
                                    col3: '30 Tage',
                                }
                            ]
                        },                          
                        {
                                        
                            title: 'Weitere Informationen',
                            description: 'Wenn Sie Fragen zu Ihren Wahlmöglichkeiten haben, <a class="cc-link" href="/kontakt">kontaktieren Sie uns</a>.',
                        }
                    ]
                }
            }
        }
    });

    if (!cc.allowedCategory('analytics')) {
        document.querySelector('body').setAttribute('data-cookies', 'false')
    }
    else {
        document.querySelector('body').setAttribute('data-cookies', 'true')
    }

    document.body.addEventListener('click', function(event) {
        if (event.target.classList.contains('footer-info-cookies')) {
            cc.showSettings();
        }
      });
}

export function updateCookieConsentLanguage() {
    cc.updateLanguage(document.documentElement.lang);
}

setupCookieConsent();