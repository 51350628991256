import autocomplete from 'autocompleter';
import $ from 'jquery';

export function initAutocomplete() {

    const searchInput = $('.newsroom-search__input');
    const existingAutoComplete = $('.autocomplete');

    if(existingAutoComplete.length > 0) {
        existingAutoComplete.remove();
    }

    if(searchInput.length == 1) {
        autocomplete({
            input: searchInput.get(0),
            emptyMsg: "Keine Suchvorschläge",
            minLength: 1,
            debounceWaitMs: 100,
            disableAutoSelect: true,
            fetch: function (text, callback, trigger, cursorPos) {
                text = text.toLowerCase();
                let resultJson = [];
                $.getJSON({
                    url: "./autocomplete?q=" + text,
                    type: 'GET',
                    success: function (result) {
                        callback(result);
                    },
                });
            },
            onSelect: function (item) {
                searchInput.val(item.label);
                location.href = './?search=' + encodeURIComponent(item.label);
            },

            render: function (item, currentValue) {
                const itemElement = document.createElement("div");
                const label = document.createElement("span");

                label.textContent = item.label;

                itemElement.appendChild(label);

                return itemElement;
            }
        });
    }

}

initAutocomplete();