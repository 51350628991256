import $ from "jquery";

let navPositions = new Map();

$(window).on('scroll load', function(){

    const offset = calculuateFixedNavOffset();

    if(offset !== false) {

        const subnavItems = $('.subnav__item');
        const scrollPosition = $(window).scrollTop();

        if(scrollPosition >= offset) {
            $('.subnav').addClass('subnav--fixed');
            $('.subnav-container').css('height', $('.subnav').outerHeight(true) + 'px');
            updateFixedNav();
        }
        else {
            $('.subnav').removeClass('subnav--fixed');
            $('.subnav-container').css('height', 'auto');
        }

        if(!$('.subnav').hasClass('subnav--static')) {

            subnavItems.removeClass('subnav__item--current');
            let foundPosition = false;

            for (let [id, position] of new Map([...navPositions.entries()].sort((a, b) => b[1] - a[1]))) {
                if(scrollPosition >= (position - $('.subnav').outerHeight(true) - 32) && !foundPosition) {
                    foundPosition = true;
                    $('[href="#' + id + '"]').addClass('subnav__item--current');
                }
            }
        }
    }
});

function updateFixedNav() {

    const blocks = $('.block, .media-topic');

    if(blocks.length > 1) {
        const subnav = $('.subnav');
        const subnavItems = $('.subnav__item');

        blocks.css("--offset", subnav.outerHeight(true) + 'px');

        blocks.each(function(){
            navPositions.set($(this).attr('id'), $(this).offset().top);
        });
    }
}

export function initFixedNav() {

    if($('.subnav-container').length === 0 || $('.subnav--static').length > 0) {
        return;
    }

    updateFixedNav();

    $('.subnav-container a').on('click', function(e){

        updateFixedNav();

        const link = $(this);
        const hash = link.prop('hash');
        const target = $(hash);
        const targetPosition = target.offset().top;


        if($(window).width() >= 640) {
            const offset = $('.subnav').outerHeight();
            history.pushState(null, null, hash);
            e.preventDefault();
            $(window).scrollTop(targetPosition - offset);
        }


    });
}

export function calculuateFixedNavOffset() {

    if($('.subnav-container').length === 1) {
        return  $('.subnav-container').offset().top;
    }
    else {
        return false;
    }
}

initFixedNav();