const directDownload = document.querySelector('[data-init-download]');

if(directDownload) {
   const url = directDownload.href;

   //wait 5 seconds and change location to url
   setTimeout(function() {
      window.location.href = url;
  }, 3000);
   
}

const sendOrderForm = document.getElementById('FormBuilder_publication-order');

function setLanguageEvents(modal, select) {
   select.addEventListener('change', function () {
      const downloadButton = modal.querySelector('.button--download');

      if(downloadButton) {
         downloadButton.href = downloadButton.dataset.defaultHref + '?lang=' + select.value;
      }
   });
}

export function initPublications() {

   const publicationButton = document.querySelectorAll('[data-micromodal-trigger="publication-modal"]');
   const modalContainer = document.querySelector('#publication-modal');

   publicationButton.forEach(function (button) {
      button.addEventListener('click', function () {
         const modalConent = document.getElementById(button.dataset.modalContent);

         modalContainer.querySelector('.modal__container').innerHTML = modalConent.innerHTML;

         const langSelection = modalContainer.querySelector('[name=lang]');

         if(langSelection) {
            setLanguageEvents(modalContainer, langSelection);
         }
      });
   });
}



initPublications();


