import { Splide } from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import MicroModal from 'micromodal';

let planer;
let planerIframe;

export function initPlaner() {
    planer = document.getElementById('planer-stage');
    planerIframe = document.getElementById('planer-iframe');

    if (planer !== null && planerIframe !== null) {
        const url = planer.dataset.planerUrl;
        const config = planer.dataset.planerConfig;

        if (window.location.hash.indexOf('CONFIG=') > -1) {
            planerIframe.setAttribute('src', url + window.location.hash);
        } else {
            planerIframe.setAttribute('src', url + config);
        }

        const exampleLists = document.getElementsByClassName('examples__list');

        Array.from(exampleLists).forEach(
            function (list, index, array) {
                const slider = new Splide(list, {
                    perPage: 2,
                    perMove: 1,
                    pagination: false,
                    gap: '2rem',
                    padding: {left: '2rem', right: '12.5rem'},
                    breakpoints: {
                        990: {
                            perPage: 1,
                        },
                        680: {
                            padding: {left: '0', right: '2rem'},
                        },
                    }
                }).mount();
            }
        );

        const expertLists = document.getElementsByClassName('planer-experts__list');

        Array.from(expertLists).forEach(
            function (list, index, array) {
                const slider = new Splide(list, {
                    type: 'loop',
                    perPage: 4,
                    arrows: false,
                    pagination: false,
                    gap: '4rem',
                    padding: {
                        left: '4rem', 
                        right: '4rem'
                    },
                    autoScroll: {
                        speed: 1,
                    },                    
                    breakpoints: {
                        900: {
                            perPage: 3,
                        },
                        680: {
                            perPage: 2,
                        },
                        460: {
                            perPage: 1,
                        },
                    }
                }).mount({ AutoScroll });
            }
        );

        const inspirationsLists = document.getElementsByClassName('planer-inspirations__list');

        Array.from(inspirationsLists).forEach(
            function (list, index, array) {
                const slider = new Splide(list, {
                    type: 'fade',
                    perPage: 1,
                    perMove: 1,
                    autoplay: true,
                    interval: 3000,
                    pagination: true,
                    lazyLoad: 'nearby',
                    rewind: true,
                    arrows: false

                });

                slider.on('dragging', function() {
                    slider.root.classList.add('carousel-dragging');
                });

                slider.on('dragged', function() {
                    setTimeout(function () {
                        slider.root.classList.remove('carousel-dragging');
                    }, 250);
                });

                slider.mount();
            }
        );

        managePlanerHeight();
        window.onresize = managePlanerHeight;

    }

}

function managePlanerHeight() {
    if (planer == null) {
        return;
    }

    let subnav = document.getElementsByClassName('subnav--planer');
    const minHeight = 800;
    const minWidth = 640;
    let height = 0;

    if (subnav.length !== 1) {
        return;
    }

    subnav = subnav[0];

    height = 'calc(100vh - 8rem)';

    planerIframe.style.height = height;

}

export function leavePlanerConfirm(targetUrl) {
    const modal = document.querySelector('#modal-confirm-nav');

    if(modal) {
        MicroModal.show('modal-confirm-nav');
        modal.querySelector('[data-allow-nav]').href = targetUrl;
    }
}

document.addEventListener('click', function (event) {

    if (!event.target.matches('.plan__button[data-plan-config]')) return;

    const button = event.target;
    const modalButton = document.getElementById('button-replace-plan');

    if(modalButton !== null) {
        const config = button.dataset.planConfig;
        const planId = button.dataset.planId;

        modalButton.dataset.planConfig = config;
        modalButton.dataset.planId = planId;
    }



}, false);


document.addEventListener('click', function (event) {

    if (!event.target.matches('#button-replace-plan')) return;

    const closeButton = document.querySelector('#modal-planer-confirm .modal__close');
    const navButtonToPlaner = document.querySelector('.subnav__item[href="#planer"]');
    const url = planer.dataset.planerUrl;
    const config = event.target.dataset.planConfig;
    const planId = event.target.dataset.planId;

    planerIframe.src = url + '?' + planId + config;

    closeButton.click();
    navButtonToPlaner.click();

}, false);





initPlaner();
