import $ from "jquery";
import { easepick } from '@easepick/core';
import { RangePlugin } from '@easepick/range-plugin';
import { nav } from "./nav";

export function initSearchButtons() {
    const button = $('.newsroom-search__clear');
    const searchInput = $('.newsroom-search__input');
    const dateRange = $('.newsroom-search__calendar');

    if(button.length == 1 && searchInput.length == 1) {
        button.on('click', function() {
            searchInput.val('');
            if(dateRange.length > 0) {
                dateRange.val('');
            }
            nav.submit(searchInput.closest('form').get(0));
        });
    }
}

export function createDateRangePicker() {

    const pickerElements = document.getElementsByClassName('newsroom-search__calendar');

    if(pickerElements.length > 0) {
        
        MutationObserver = window.MutationObserver || window.WebKitMutationObserver;

        var trackChange = function(element) {
          var observer = new MutationObserver(function(mutations, observer) {
            if(mutations[0].attributeName == "value") {
                $(element).trigger("change");
            }
          });
          observer.observe(element, {
            attributes: true
          });
        }

        let rangePluginSettings = {};

        if($('html').attr('lang') == 'de') {
            rangePluginSettings = {
                locale: { one: "Tag", other: "Tage" }
            };
        }
        
        const picker = new easepick.create({
            element: ".newsroom-search__calendar",
            css: [
                '/site/templates/styles/screen.css',
            ],
            zIndex: 10,
            lang: $('html').attr('lang'),
            inline: true,
            plugins: [RangePlugin],
            RangePlugin: rangePluginSettings
        });

        const dateInput = $('.newsroom-search__calendar[data-from]');

        if(dateInput.length == 1) {
            picker.setDateRange(dateInput.data('from'), dateInput.data('to'));
            picker.gotoDate(dateInput.data('from'));
        }

        trackChange($('.newsroom-search__calendar')[0]);

        $('.newsroom-search__calendar').on('change', evt => {
            nav.submit($(evt.delegateTarget).closest('form').get(0));
        });


    }
}

createDateRangePicker();
initSearchButtons();