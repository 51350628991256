import $ from 'jquery';

export function initFormFrames() {
    const formFrames = $('.FormBuilderViewport');

    if(formFrames.length > 0) {

        formFrames.each(function() {
            $(this).on('load', () => {
                
                const iframe = $(this)
                const iframeContents = iframe.contents();
                const iframeErrors = iframeContents.find('.FormBuilderErrors, #FormBuilderSubmitted');

                if(iframeErrors.length > 0) {
                    iframe.get(0).scrollIntoView();
                }
            })
        })

    }

}

initFormFrames();




