import $ from "jquery";
import { loadVideos } from "./video";

export const htmlOverlay = '<div class="media-overlay"><div class="media-overlay__inner"></div></div>';

$('body').on('click', '[data-media-html]', function(e){

    e.preventDefault();

    const target = $($(this).data('mediaHtml'));

    $('.media-overlay').remove();

    $(htmlOverlay).insertAfter('.page');
    $('.media-overlay__inner').html(target.html());

    loadVideos(true);

});

$('body').on('click', '[data-close]', function(){

    $('.media-overlay').remove();
});

$('body').on('click', '.video-vimeo__mini', function(){
    $('.media-overlay').toggleClass('media-overlay--mini');
});

export function checkForActiveMediaOverlay() {
    $('.media-item--active').find('[data-media-html]').trigger('click');
}

checkForActiveMediaOverlay();

