import Plyr from 'plyr';
import dashjs from 'dashjs';

export function loadVideos(isModal) {

    let elementClass = '.article-block__video, .manual__video, .about-us__video-player';

    if(isModal === true) {
        elementClass = '.media-overlay .modal__video';
    }

    document.querySelectorAll('video.newsroom-teaser__image-img, video.article-header__visual-img').forEach(video => {
        var source = document.createElement('source');

        source.setAttribute('src', video.dataset.src);
        source.setAttribute('type', 'video/mp4');

        video.replaceChildren(source);
        video.play();
    });

    document.querySelectorAll(elementClass).forEach(element => {

        if(element.dataset.plyrProvider === 'youtube') {
            const player = new Plyr(element, {
                iconUrl: '/site/templates/images/plyr.svg',
                youtube: { noCookie: true, rel: 0, showinfo: 0, iv_load_policy: 3, modestbranding: 1 },
                ratio: '16:9'
            });
        }
        else {
            const player = new Plyr(element, {
                iconUrl: '/site/templates/images/plyr.svg',
                captions: {
                    active: false
                },
            });
        }
    });
}

loadVideos();