import $ from "jquery";
import { calculuateFixedNavOffset } from './fixed-nav';

var timeout = null;

$('body').on('keyup', '[name="media-centre-search"]', function() {

    clearTimeout(timeout);

    window.scrollTo(calculuateFixedNavOffset(), 0);

    timeout = setTimeout(() => {
        const searchField = $(this);
        const resultsContainer = $('.media-search-results');
        const searchQuery = searchField.val();
        const mediaTopics = $('.media-topics');
        const mediaNav = $('.media-nav');
    
        
        let results = [];

        resultsContainer.html('');

        if(searchQuery.length > 0) {
            searchField.addClass('media-search__input--filled');
        }
        else {
            searchField.removeClass('media-search__input--filled');
        }
    
        if(searchQuery.length < 3) {
            mediaTopics.show();
            mediaNav.show();
            resultsContainer.hide();
            return;
        }

        mediaTopics.hide();
        mediaNav.hide();
        resultsContainer.show();

        const foundItems = $('.media-item__headline, .media-item__description p').filter(function() {
            var reg = new RegExp(searchQuery, "i");
            return reg.test($(this).text());
        });


        if(foundItems.length == 0) {
            resultsContainer.html('<div class="media-topic__inner typography"><p>' + searchField.data('noResults') + '</p></div>');
            return;

        }
    
        for (const item of foundItems) {
            const mediaItem = item.closest('.media-item');
            const mediaId = mediaItem.dataset.mediaId;
            results[mediaId] = mediaItem;
        }
    
        resultsContainer.html('<div class="media-topic__inner"><ul class="media-search-results__list"></ul></div>');
    
    
        for (const [itemId, mediaItem] of Object.entries(results)) {
            resultsContainer.find('ul').append('<li class="media-item">' + mediaItem.innerHTML + '</li>');
        }
    }, 200);
});

export function initMediaCenterSearch() {
    const clearButton = $('.media-search__clear');
    const inputField = $('.media-search__input');
    const mediaTopics = $('.media-topics');
    const resultsContainer = $('.media-search-results');

    if(clearButton.length === 1) {

        clearButton.on('click', function () {
            window.scrollTo(calculuateFixedNavOffset(), 0);
            inputField.val('');
            inputField.removeClass('media-search__input--filled');
            mediaTopics.show();
            resultsContainer.hide();
        });
    }
}

initMediaCenterSearch();