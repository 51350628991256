import $ from 'jquery';
import 'jquery-hoverintent/jquery.hoverIntent.js';
import { nav } from './nav';

function changeNav(targetId) {
    const section = $(targetId);
    const html = section.html();
    const nav = $('.header-map__nav');

    nav.html(html);
}

function closeNav() {
    $('[data-toggle]').trigger('click');
}

export function createMapLabels() {
    $("[data-label]").each(function () {
        $(".header-map__labels").append('<span class="header-map__label" id="label-' + $(this).attr('id') + '">' + $(this).data('label') +'</span>');
    });

    $(".header-map__visual svg > g").hoverIntent(
        function() {
            $('.header-map svg > g').removeClass('visible');
            $(this).addClass('visible');
    
            changeNav('#nav-' + $(this).attr('id'));
        }
    );
    
    $(".header-map__visual svg").hoverIntent(
        function() {
            $('.header-map__label').addClass('header-map__label--visible');
        },
        function() {
            $('.header-map__label').removeClass('header-map__label--visible');
        }
    );

    $(".header-map__visual svg > g").on('click', function (){
        const navTarget = $('#nav-' + $(this).attr('id')).find('li:first-child a,nav a:first-child');
        $('.header-map__nav-item--current').removeClass('header-map__nav-item--current');
        $('.header-map__nav-item a[href="' + navTarget + '"]').parent().addClass('header-map__nav-item--current');

        if($(this).data('external')) {
            var anchor = document.createElement('a');
            anchor.href = navTarget.attr('href');
            anchor.target="_blank";
            anchor.click();
        }
        else {
            nav.go(navTarget.attr('href'));
        }
    });

}

createMapLabels();

$('[data-toggle]').on('click', function() {
    const target = $($(this).data('toggle'));
    const toggleClass = $(this).data('toggleClass');

    target.toggleClass(toggleClass);

    $(this).find('#nav-toggle').toggleClass('open');

    if(!target.hasClass(toggleClass)) {
        $('.header-map svg > g').removeClass('visible');
        changeNav('#nav-info');
    }
});

document.addEventListener("DOMContentLoaded", function() {
    if (document.querySelector('.page--home') && document.cookie.indexOf('cc_cookie') === -1) {
        $('[data-toggle]').trigger('click');
    }
});