import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    static targets = ['form', 'results'];

    connect() {
        //iterate current form has values and perform a search if yes
        const form = this.formTarget;
        const inputs = form.querySelectorAll('input, select');

        inputs.forEach(input => {
            if(input.value) {
                this.getResults();

            }
        });

    }

    search(event) {

        if(event.type === 'keydown' && event.key === 'Enter') {
            event.preventDefault();
            this.getResults();
            return;
        }

        if(event.type === 'keyup') {
            event.preventDefault();

            //delay search and avoid multiple requests
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.getResults();
            }, 300);

            return;
        }

        this.getResults();
    }

    getResults() {

        const form = this.formTarget;
        const url = form.action;
        const data = new FormData(form);

        //remove empty values from form data
        for (let [key, value] of data.entries()) {
            if (!value) {
                data.delete(key);
            }
        }

        const queryString = new URLSearchParams(data).toString();

        fetch(url + '?' + queryString, {
            method: 'GET',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            }
        })
            .then(response => response.text())
            .then(html => {
                this.resultsTarget.innerHTML = html;

            }
        );

        //remove current query string and add new one
        const currentUrl = location.href.split('?')[0];
        const newUrl = currentUrl + '?' + queryString;

        history.pushState({}, '', newUrl);
    }

    showAll(event) {

        event.preventDefault();

        const form = this.formTarget;
        const inputs = form.querySelectorAll('input, select');

        inputs.forEach(input => {
            input.value = '';
        });

        this.getResults();
    }



}