import Splide from '@splidejs/splide';
import $ from 'jquery';

document.addEventListener('DOMContentLoaded', function () {
    loadCarousel();
});

$.fn.shuffleChildren = function() {
    $.each(this.get(), function(index, el) {
        var $el = $(el);
        var $find = $el.children();

        $find.sort(function() {
            return 0.5 - Math.random();
        });

        $el.empty();
        $find.appendTo($el);
    });
};

export default function loadCarousel() {
    if (document.getElementsByClassName('carousel').length > 0) {

        var carousels = document.getElementsByClassName( 'carousel' );

        for ( var i = 0; i < carousels.length; i++ ) {

            const carouselElement = $(carousels[i]);

            if(carouselElement.hasClass('carousel--shuffle')) {
                carouselElement.find('.splide__list').shuffleChildren();
                if(carouselElement.find('.preview-list__list--rows').length > 0) {
                    carouselElement.find('.preview-list__list--rows').shuffleChildren();
                }
            }

            const carousel = new Splide(carousels[i], {
                perPage: carousels[i].dataset.perPage,
                perMove: carousels[i].dataset.perMove,
                isNavigation: false,
                pagination: false,
                gap: '1.5rem',
                drag: true,
                padding: { right: '6rem'},
                
                breakpoints: {
                
                    640: {
                        perPage: Math.round(carousels[i].dataset.perPage / 2),
                        padding: { right: '3rem'}
                    },
                    1235: {
                        padding: { right: '4rem'}
                    }
                },  

            });

            carousel.on('dragging', function() {
                carousel.root.classList.add('carousel-dragging');
            });

            carousel.on('dragged', function() {
                setTimeout(function () {
                    carousel.root.classList.remove('carousel-dragging');
                }, 250);
            });

            carousel.mount();
        }
    }
}