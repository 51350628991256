import { Controller } from "@hotwired/stimulus"
import { addSpinner, removeSpinner } from "../js/spinner";

export default class extends Controller {

   static targets = [ "orderForm", "orderFormContainer", "cartModalContent" ];

   togglePrint(event) {
      const checkbox = event.currentTarget;
      const url = checkbox.dataset.url;
      const fileId = checkbox.dataset.fileId;
      const publicationId = checkbox.dataset.publicationId;
      const csrfTokenName = checkbox.dataset.csrfName;
      const csrfTokenValue = checkbox.dataset.csrfToken;

      let formData = new FormData();
      formData.append('action', 'print');
      formData.append('print', checkbox.checked);
      formData.append('file_id', fileId);
      formData.append('publication_id', publicationId);
      formData.append('action', 'print');
      formData.append(csrfTokenName, csrfTokenValue)

      let headers = {
         "X-Requested-With": "XMLHttpRequest",
      };

      fetch(url, {
         method: 'POST',
         headers: headers,
         body: formData,
      })
      .then(response => {
         if (response.ok) {
             return response.json();
         } else {
             throw new Error('Something went wrong');
         }
      })
      .then(data => {
         //set checked propery of checkbox
         checkbox.checked = data.status;
      })
   }

   navigate(event) {
      const button = event.currentTarget;
      const modal = button.closest('.cart-modal__content');
      const csrfTokenName = button.dataset.csrfName;
      const csrfTokenValue = button.dataset.csrfToken;
      const nav = button.dataset.nav;

      const url = button.dataset.url;

      let formData = new FormData();
      formData.append('nav', nav);
      formData.append(csrfTokenName, csrfTokenValue);

      if(modal.querySelector('input[name="cart_terms"]:checked')) {
         formData.append('cart_terms', modal.querySelector('input[name="cart_terms"]:checked').value);
      }

      addSpinner(modal);

      let headers = {
         "X-Requested-With": "XMLHttpRequest",
      };

      fetch(url, {
         method: 'POST',
         headers: headers,
         body: formData,
      })
      .then(response => {
         if (response.ok) {
            return response.json();
         } else {
            throw new Error('Something went wrong');
         }
      })
      .then(data => {
         removeSpinner(modal);
         modal.innerHTML = data.html;
         modal.closest('.modal').querySelector('.modal__title').innerText = data.title;
      })
   }

   submit(event) {
      const formData = new FormData(this.orderFormTarget);

      //validate form before submit
      // if (this.orderFormTarget.checkValidity() === false) {
      //    this.orderFormTarget.reportValidity();
      //    return;
      // }

      addSpinner(this.cartModalContentTarget);

      fetch(this.orderFormTarget.action, {
         method: 'POST',
         body: formData
      })
          .then(response => response.text()) // Optional: Verarbeite die Serverantwort
          .then(data => {
             const responseElement = document.createElement('div');
             responseElement.innerHTML = data;

             const submittedFormResponse = responseElement.querySelector('#form-response');

             if (submittedFormResponse && submittedFormResponse.dataset.successUrl) {
                location.href = submittedFormResponse.dataset.successUrl;
             }
             else if(submittedFormResponse) {
                this.orderFormContainerTarget.innerHTML = submittedFormResponse.innerHTML;
                removeSpinner(this.cartModalContentTarget);
             }
             else {
                removeSpinner(this.cartModalContentTarget);
             }


          })
          .catch(error => {
             // Optional: Handhabung von Fehlern
          });
   }


}