import SimpleLightbox from "simplelightbox/dist/simple-lightbox.esm";

export function lightboxUpdate() {
    new SimpleLightbox('.lightbox', {
        'scrollZoom': false,
        'captionPosition': 'outside',
    });
}

lightboxUpdate();
