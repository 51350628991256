import MicroModal from 'micromodal';
import $ from "jquery";

export function initTalkIframeModals() {

    MicroModal.init({
        onShow: function(modal) {

            if($(modal).find('[data-iframe]').length == 1) {

                const iframeContainer = $(modal).find('[data-iframe]');
                const iframeLink = iframeContainer.data('iframe');
                const iframeHtml = '<iframe id="FormBuilderViewport_register-talk" src="' + iframeLink + '" class="FormBuilderViewport" data-form="register-talk" allowtransparency="true" scrolling="no" frameborder="0" crossorigin></iframe>';

                iframeContainer.html(iframeHtml);
            }
        },
        onClose: function(modal) {
            if($(modal).find('[data-iframe]').length == 1) {

                const iframeContainer = $(modal).find('[data-iframe]');
                iframeContainer.html('');

            }            
        }
    });

}

initTalkIframeModals();