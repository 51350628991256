import { Splide } from '@splidejs/splide';
import { URLHash } from '@splidejs/splide-extension-url-hash';
import { Video } from "@splidejs/splide-extension-video";
import $ from "jquery";
import MicroModal from 'micromodal';

const page = $('.page');
let splides = [];
let currentModalSlider;

$.fn.shuffleChildren = function() {
    $.each(this.get(), function(index, el) {
      var $el = $(el);
      var $find = $el.children();
  
      $find.sort(function() {
        return 0.5 - Math.random();
      });
  
      $el.empty();
      $find.appendTo($el);
    });
  };
  

document.addEventListener('DOMContentLoaded', function () {
    loadSplide();
    loadShowroomButtons();

});

export function showroomNavUpdate() {

    // splides.forEach(function(splide, index){
    //     splide.destroy(true);
    // });

    splides = [];

    loadSplide();
    loadShowroomButtons();
    toggleShowroomMeta(false);
}

export function loadShowroomButtons() {
    MicroModal.init({
        onShow: function(modal) {
            const sliderElement = $(modal).find('.modal-slider');
            const modalContent = $(modal).find('.modal__content');

            if(sliderElement.length > 0) {
                currentModalSlider = new Splide(sliderElement.get(0), {
                    type   : sliderElement.data('type'),
                    perPage: 1,
                    pagination: false,
                    video: {
                        playerOptions: {
                            youtube: {
                                modestbranding: 1,
                                rel: 0,
                                showinfo: 0,
                                iv_load_policy: 3
                            },
                            vimeo: {
                                title: false,
                                dnt: true,
                                portrait: false
                            },
                            htmlVideo: {},

                        }
                    }
                });
            

                currentModalSlider.on('mounted', function(){

                    modalContent.get(0).scrollTop = 0;
                });

                currentModalSlider.mount({Video});
            }
        },
        onClose: function(modal) {
            if(typeof currentModalSlider !== 'undefined') {
                currentModalSlider.destroy();
            }
        },
        disableScroll: true,
    });
}

export function loadSplide() {
    if (document.getElementsByClassName('showroom-thumbnails__splide').length > 0) {


        if($('.showroom-slider').is('[data-shuffle]')) {
            $('.showroom-thumbnails__splide .splide__list').shuffleChildren();
            
            let orderedSlides = [];
            
            $('.showroom-thumbnails__splide .splide__list > *').each(function() {
                const slideId = $(this).data('slideId');
                orderedSlides.push($('.showroom-slider [data-slide-id="' + slideId + '"]'));
            }); 

            $('.showroom-slider .splide__list').empty();

            orderedSlides.forEach(function(slide){
                $('.showroom-slider .splide__list').append(slide);
            });

        }

        var thumbnails = new Splide('.showroom-thumbnails__splide', {
            perPage: 4,
            perMove: 4,
            isNavigation: false,
            pagination: false,
            lazyLoad: 'nearby',
            preloadPages: 1,
            padding: { right: '4.5rem' },
            breakpoints: {            
                1380: {
                    perPage: 3,
                    perMove: 3
                },     
                820: {
                    padding: { right: '2rem' },
                    perPage: 3,
                    perMove: 1
                },                                   
            }, 
        }).mount();

        var slider = new Splide('.showroom-slider', {
            type: 'fade',
            pagination: false,
            arrows: false,
            cover: false,
            drag: false,
            keyboard: false,
            lazyLoad: 'sequential',
        });

        thumbnails.on('click', function (e) {
            slider.go(e.index);
            thumbnails.go(e.index);
        });

        slider.on('active', function (e) {
            const slide = thumbnails.Components.Slides.getAt(e.index).slide;
            const showroomMoreButton = $(e.slide).find('.showroom-slider-buttons .button--more');
            const mobileShowroomMoreButton = $('#showroom-button-mobile');
            const langSwitcher = $('.lang-switcher');

            $('.showroom-thumbnails__current').removeClass('showroom-thumbnails__current');
            slide.classList.add('showroom-thumbnails__current');
            toggleShowroomMeta(false);

            $('.showroom-mobile__buttons--open').removeClass('showroom-mobile__buttons--open');

            thumbnails.Components.Controller.go(e.index);

            mobileShowroomMoreButton.attr('href', showroomMoreButton.attr('href'));
            mobileShowroomMoreButton.text(showroomMoreButton.text());

            langSwitcher.attr('href', langSwitcher.data('hrefDefault') + '#' + $('.is-active[data-splide-hash]').data('splideHash'));

        });

        slider.mount({URLHash});

        splides.push(slider);
        splides.push(thumbnails);
    }
}

export function toggleShowroomMeta(metaHtml) {
    const showroomSlider = $('.showroom.block');
    const showroomMeta = $('.showroom-meta');
    const showroomMetaContent = showroomMeta.find('.showroom-meta__content');

    //hide
    if(metaHtml === false) {
        showroomMeta.css('top', '100%');
        setTimeout(function() {
            showroomMeta.removeClass('showroom-meta--visible');
        }, 500);
    }
    //show
    else {
        const metaPosition = showroomSlider.height() + showroomSlider.position().top - 24;
        showroomMetaContent.html(metaHtml);
        showroomMeta.css('top', metaPosition + 'px');
        showroomMeta.addClass('showroom-meta--visible');
    }
}

page.on('click', '.showroom-slider-buttons__logo', function() {

    const button = '<svg viewBox="0 0 53 53" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;">\n' +
        '    <g transform="matrix(1,0,0,1,-130.349,-2016.64)">\n' +
        '        <g transform="matrix(1.33333,0,0,1.33333,0,0)">\n' +
        '            <g transform="matrix(0.707094,0.70712,0.70712,-0.707094,117.435,1512.59)">\n' +
        '                <path d="M-5.728,-13.828L33.383,-13.828" style="fill:none;fill-rule:nonzero;stroke:rgb(130,147,156);stroke-width:2.83px;"/>\n' +
        '            </g>\n' +
        '        </g>\n' +
        '        <g transform="matrix(1.33333,0,0,1.33333,0,0)">\n' +
        '            <g transform="matrix(0.707094,-0.70712,-0.70712,-0.707094,117.434,1551.71)">\n' +
        '                <path d="M-5.728,13.828L33.383,13.828" style="fill:none;fill-rule:nonzero;stroke:rgb(130,147,156);stroke-width:2.83px;"/>\n' +
        '            </g>\n' +
        '        </g>\n' +
        '    </g>\n' +
        '</svg>';

    if($('.showroom-meta').length === 0) {
        page.append('<div class="showroom-meta"><button class="showroom-meta__close">' + button + '</button><div class="showroom-meta__content"></div></div>');
    }

    const showroomMeta = $('.showroom-meta');
    const metaElement = $(this).parent().siblings('.showroom-slider__meta');

    if(metaElement.length === 0) {
        return;
    }


    toggleShowroomMeta(metaElement.html());

});

page.on('click', '.showroom-meta__close', function() {
    toggleShowroomMeta(false);
});

page.on('click', '.showroom-info__button--mobile', function(){
    const showRoomInfoBox = $('#' + $(this).data('toggle'));

    showRoomInfoBox.toggleClass('showroom-mobile__buttons--open');
});


page.on('click', '[data-modal-xhr]', function() {
    
    const xhrUrl = $(this).data('modalXhr');

    $.ajax({
        url: xhrUrl,
        success: function(response) {
            const modal = $(response).appendTo('body');

            MicroModal.show(modal.attr('id'), 
            {
                onShow: function(modal) {
                    const sliderElement = $(modal).find('.modal-slider');
                    const modalContent = $(modal).find('.modal__content');
        
                    if(sliderElement.length > 0) {
                        currentModalSlider = new Splide(sliderElement.get(0), {
                            type   : sliderElement.data('type'),
                            perPage: 1,
                            pagination: false,
                            video: {
                                playerOptions: {
                                    youtube: {
                                        modestbranding: 1,
                                        rel: 0,
                                        showinfo: 0,
                                        iv_load_policy: 3
                                    },
                                    vimeo: {
                                        title: false,
                                        dnt: true,
                                        portrait: false
                                    },
                                    htmlVideo: {},
        
                                }
                            }
                        });
                    
                        currentModalSlider.on('mounted', function(){
        
                            modalContent.get(0).scrollTop = 0;
                        });
        
                        currentModalSlider.mount({Video});
                    }
                },
                onClose: function(modal) {
                    if(typeof currentModalSlider !== 'undefined') {
                        currentModalSlider.destroy();
                    }
                    $(modal).remove();
                },
                disableScroll: true,
            }
            );
        }
    })

});
