import Splide from '@splidejs/splide';
import $ from "jquery";

document.addEventListener('DOMContentLoaded', function () {
    loadTopicSplide();

});

export function loadTopicSplide() {
    if (document.getElementsByClassName('topic-list__splide').length > 0) {

        var slider = new Splide('.topic-list__splide', {

            pagination: true,
            arrows: false,
            drag: true,
            keyboard: true,
            gap: '2rem',
         
        }).mount();

    }
}