// global
import './stimulus';

import './js/nav';
import './js/modal';
import './js/header';
import './js/carousel';
import './js/media-overlay';
import './js/media-search';
import './js/fixed-nav';
import '../global/js/consent';
import './js/topic-list';
import './js/lightbox.js';
import './js/newsroom-archive';
import './js/newsroom-search';
import './js/video';
import './js/press';
import './js/forms';
import './js/publications';
//import './js/webinar'; uncomment for Talks
import './js/toggles';
import './js/talks';
import './js/planer';

import './scss/screen.scss';


// blocks
import './js/showroom';
import {initAutocomplete} from "./js/newsroom-search";

